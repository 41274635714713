import React from "react";
import Layout from "./Layout";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const ContactUsForm = ({ darkMode }) => {
  return (
    <Layout darkMode={darkMode}>
      <div className="flex justify-center py-16 bg-gray-100">
        <div
          className="bg-white shadow-lg text-left rounded-lg flex w-[80%] max-w-[1200px] overflow-hidden"
          style={{ borderTopRightRadius: "3rem" }}
        >
          {/* Left Section */}
          <div className="w-1/2 p-10 bg-white">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Transform Your Workday!
            </h2>
            <p className="text-xl text-gray-700 mb-6 max-w-[380px]">
              Are you ready to take your productivity to new heights? Join us at
              <span className="text-[#1392b5] font-bold">
                &nbsp;V Assist Pro Inc.
              </span>{" "}
              for a quick <span className="font-bold underline">20-minute</span>{" "}
              discovery call!
            </p>
            <p className="text-xl text-gray-700 mb-6 max-w-[380px]">
              We’ll explore how our{" "}
              <span className="font-bold underline">
                dedicated Productivity Assistants
              </span>{" "}
              can streamline your operations and empower your team. Let’s make
              your success our mission!
            </p>

            <div className="text-gray-700">
              <div className="flex items-center mb-4">
                <FaMapMarkerAlt className="text-orange-500 mr-2" />
                <span>5825 Gulfton St #3205 Houston, TX 77081, USA</span>
              </div>
              <div className="flex items-center">
                <FaPhoneAlt className="text-orange-500 mr-2" />
                <a
                  href="tel:+19416234590"
                  className="text-gray-700 hover:underline"
                >
                  (941) 623-4590
                </a>
              </div>
              <div className="flex items-center mt-3">
                <FaPhoneAlt className="text-orange-500 mr-2" />
                <a
                  href="tel:+17037455460"
                  className="text-gray-700 hover:underline"
                >
                  (703) 745-5460
                </a>
              </div>
              <div className="flex items-center mt-3">
                <FiMail className="text-orange-500 mr-2 size-5" />
                <a
                  href="mailto:info@virtualassistancepro.com"
                  className="text-gray-700 hover:underline"
                >
                  info@virtualassistancepro.com
                </a>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div
            className="w-1/2 bg-gray-900 text-white p-10 rounded-tr-3xl"
            style={{ borderTopRightRadius: "3rem" }}
          >
            <h3 className="text-2xl font-bold mb-6">
              Let's talk about your assistant needs.
            </h3>
            <form>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="firstName"
                  >
                    First Name *
                  </label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="text"
                    id="firstName"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="lastName"
                  >
                    Last Name *
                  </label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="text"
                    id="lastName"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="businessEmail"
                  >
                    Business Email *
                  </label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="email"
                    id="businessEmail"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="phoneNumber"
                  >
                    Phone Number *
                  </label>
                  <input
                    className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                    type="tel"
                    id="phoneNumber"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-2"
                  htmlFor="interestedServices"
                >
                  Why are you interested in our services? *
                </label>
                <textarea
                  className="w-full h-24 px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                  id="interestedServices"
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-2"
                  htmlFor="supportLookingFor"
                >
                  What support are you looking for? *
                </label>
                <select
                  className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                  id="supportLookingFor"
                  required
                >
                  <option value="">Please Select</option>
                  <option value="Option1">Support for one person</option>
                  <option value="Option2">Support for multiple people</option>
                  <option value="Option3">Looking for a job</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-2"
                  htmlFor="hearAboutUs"
                >
                  How did you hear about us?
                </label>
                <input
                  className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                  type="text"
                  id="hearAboutUs"
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 transition duration-300"
              >
                Schedule Your Call
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsForm;
